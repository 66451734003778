<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">我的钱包</div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_container">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item name="1">
                    <div slot="title">
                        <i class="fa fa-money"></i> 我的余额 <span class="my_balance">￥{{ this.balance }}</span>
                    </div>
                    <div class="balance_container">
                        <el-card>
                            <div class="withdraw_container">
                                <span class="withdraw_title">提现金额（元）</span>
                                <div class="withdraw_input">
                                    <strong class="withdraw_strong">￥</strong>
                                    <input type="number" step="0.01" class="withdraw_inputs" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="请输入金额" v-model="withdrawMoney">
                                </div>
                                <div class="withdraw_tip">单次最多可提现金额200元</div>
                            </div>
                        </el-card>
                        <div class="withdraw_btn_container">
                            <!-- <el-button type="success" class="withdraw_btn" @click="requestWithdraw">发起申请</el-button> -->
                            <el-button type="success" class="withdraw_btn" @click="isapply = true">发起申请</el-button>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <div slot="title">
                        <i class="fa fa-list"></i> 历史账单
                    </div>
                    <div class="bill_container">
                        <ul class="el-timeline">
                            <li class="el-timeline-item" :key="item.id" v-for="item in bill">
                                <div class="el-timeline-item__tail"></div>
                                <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--"></div>
                                <div class="el-timeline-item__wrapper">
                                    <div class="el-timeline-item__timestamp is-top">
                                        {{ item.createtime.slice(0,10) }}
                                    </div>
                                    <div class="el-timeline-item__content">
                                        <el-card>
                                            <h4>提现</h4>
                                            <p>金额：{{ item.amount }} 元</p>
                                            <p>提现时间：{{ item.createtime }}</p>
                                            <p>预留支付宝账号: {{ item.zfb }}  {{ item.name }}</p>
                                            <p v-if="item.ischeck === '0'" style="color: green">状态: 已返款</p>
                                            <p v-if="item.ischeck === '1'">状态: 待返款</p>
                                            <p v-if="item.ischeck === '2'" style="color: red">状态: 已退回</p>
                                            <p v-if="item.ischeck === '1'" style="color: red">一般2-3天返款，无异常情况无需联系管理</p>
                                            <p v-if="item.ischeck === '2'" style="color: red">预留账号有误，已退回钱包</p>
                                            <p v-if="item.ischeck === '2'" style="color: red">请重新申请，如有疑问可联系管理员</p>
                                        </el-card>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
        <div class="weui-toptips weui-toptips_success" :class="isTopsuccess?'opacitied':'noopacitied'">{{ this.topSuccess }}</div>
        <div v-if="!this.islogin">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">请添加客服群获取链接</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                </div>
            </div>
        </div>
        <div v-if="this.isapply">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <span style="color: red; margin-bottom: 20px; font-family: Arial, Helvetica, sans-serif">一般2-3天返款，无异常情况无需联系管理</span>
                    <div class="icon-box">
                        <el-input v-model="zfb" placeholder="请输入支付宝账号"></el-input>
                        <el-input style="margin-top: 5px" v-model="name" placeholder="请输入姓名"></el-input>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="isapply = false">取消</a>
                    <a class="weui-dialog__btn weui-dialog__btn_primary" @click="requestWithdraw2">确认</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.islogin) {
            this.$nextTick(async function () {
            const { data: balance } = await this.$http.get('getBalance')
            if (balance.status === 200) {
                this.balance = balance.data
                this.withdrawMoney = this.balance === 0 ? '' : this.balance
            } else if (balance.status === 557) {
                this.topWarn = balance.msg
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            }
            const { data: bill } = await this.$http.get('getBill')
            if (bill.status === 200) {
                this.bill = bill.data.reverse()
            } else if (bill.status === 557) {
                this.topWarn = bill.msg
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            }
        })
        }
    },
    data () {
        return {
            isTopsuccess: false,
            isTopwarn: false,
            topWarn: '',
            topSuccess: '',
            withdrawMoney: '',
            bill: [],
            balance: '0',
            activeName: '1',
            islogin: false,
            userInfo: {},
            isapply: false,
            zfb: '',
            name: ''
        }
    },
    computed: {
        // filterBill: function () {
        //     return this.bill.filter(t => t.ischeck === '0').reverse()
        // }
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        getKf () {
            this.$router.push('/home')
        },
        apply () {

        },
        requestWithdraw () {
            if (this.withdrawMoney < 0.3) {
                this.topWarn = '最低提现金额0.3元'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else if (this.withdrawMoney > this.balance) {
                this.topWarn = '余额不足'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else if (this.withdrawMoney > 200) {
                this.topWarn = '超出限额'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else {
                this.$nextTick(async function () {
                const { data: res } = await this.$http.get('withDraw', {
                    params: {
                        amount: this.withdrawMoney
                    }
                })
                if (res.status === 200) {
                    this.withdrawMoney = ''
                    this.activeName = '2'
                    this.bill.push(res.data.bill)
                    this.balance = res.data.remain
                } else {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            })
            }
        },
        requestWithdraw2 () {
            if (!this.zfb) {
                this.topWarn = '请输入支付宝账号'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
                return
            // } else {
            //     var reg = /^1[3456789]\d{9}$/
            //     if (!reg.test(this.zfb)) {
            //         this.topWarn = '请输入正确的支付宝账号'
            //         this.isTopwarn = true
            //         setTimeout(() => {
            //             this.isTopwarn = false
            //             this.topWarn = ''
            //         }, 2000)
            //         return
            //     }
            }
            if (!this.name) {
                this.topWarn = '请输入姓名'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
                return
            }
            if (this.withdrawMoney < 0.3) {
                this.topWarn = '最低提现金额0.3元'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else if (this.withdrawMoney > this.balance) {
                this.topWarn = '余额不足'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else if (this.withdrawMoney > 200) {
                this.topWarn = '超出限额'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            } else {
                this.$nextTick(async function () {
                const { data: res } = await this.$http.get('withDraw2', {
                    params: {
                        amount: this.withdrawMoney,
                        zfb: this.zfb,
                        name: this.name
                    }
                })
                if (res.status === 200) {
                    this.isapply = false
                    this.withdrawMoney = ''
                    this.activeName = '2'
                    this.bill.unshift(res.data.bill)
                    this.balance = res.data.remain
                } else {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 5000)
                }
            })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.weui-toptips_success {
    background-color: rgb(26, 161, 48);
}
.main_container {
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.request_container {
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
}
.balance_container {
    width: 90%;
    margin: 0 auto;
}
.withdraw_container {
    background: #fff;
    position: relative;
}
.withdraw_title {
    font-size: 12px;
    color: #333;
    font-weight: normal;
}
.withdraw_input {
    margin-top: 12px;
    padding-bottom: 18px;
    position: relative;
    color: #333;
}
.withdraw_strong {
    position: absolute;
    columns: #000;
    font-size: 28px;
    line-height: 1;
    left: -6px;
    top: -3px;
    font-weight: normal;
}
.withdraw_inputs {
    padding-left: 28px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: 0;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
}
.withdraw_tip {
    font-size: 12px;
    color: #9e9e9e;
    margin-top: 14px;
}
.withdraw_btn_container {
    width: 80%;
    margin: 5px auto;
}
.withdraw_btn {
    width: 100%;
}
.el-collapse-item__header div {
    width: 100%;
}
.my_balance {
    float: right;
    padding-right: 10px;
}
.bill_container {
    padding-left: 5px;
    p {
        font-size: 12px;
    }
    h4 {
        margin-bottom: 2px;
    }
}
</style>
