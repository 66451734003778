<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">我的收藏</div>
                <div class="header_r">
                    <i class="fa fa-trash" @click="changeDialog"></i>
                </div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_container">
            <div class="weui-navbar">
                <div class="weui-navbar__item" :class="index === selectNavbar ? 'weui-bar__item--on' : ''" :key="item.id" v-for="(item, index) in navbarGroups" @click="changeNavbar(index)">{{ item }}</div>
            </div>
            <div class="coupons_container">
                <div class="coupon_container" :key="item.id" v-for="item in filterCouponList"  :class="selectNavbar !== 0 ? 'gray_img' : ''">
                    <div class="coupon">
                        <router-link :to="{ name: 'detail', query: { goodsId: item.goodsid, id: item.iid === '-1' ? '' : item.iid } }" class="coupon_position">
                            <div class="coupon_img">
                                <img :src="item.img" alt="">
                                <div :class="item.expire?'coupon_tip':'noblocked'" v-if="selectNavbar === 0">即将过期</div>
                                <div :class="item.expire?'coupon_tip':'noblocked'" v-if="selectNavbar !== 0">已过期</div>
                            </div>
                            <div class="coupon_info">
                                <div class="coupon_title">{{ item.title }}</div>
                            </div>
                            <div class="coupon_time">
                                <span class="time">开始时间 {{ item.starttime }}</span>
                                <span class="time">结束时间 <i>{{ item.endtime }}</i></span>
                            </div>
                            <div class="coupon_money">
                                <p class="coupon_price">券后&nbsp;￥&nbsp;<em class="sell_price">{{ item.newprice }}</em></p>
                                <p class="coupon_discount quan_bg"><span class="num_discount">{{ item.quannum }}元券</span></p>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="noorders" v-if="filterCouponList.length === 0">无收藏数据</div>
            </div>
        </div>
        <div :class="isdialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <div class="icon-box">
                        <i class="weui-icon-info weui-icon_msg"></i>
                        <div class="icon-box-info">是否清空我的收藏？</div>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="changeDialog">取消</a>
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="deleteCollect">确认</a>
                </div>
            </div>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
        <div v-if="!this.islogin">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">请添加客服群获取链接</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.islogin) {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('getCollect', {
                    params: {
                        userId: this.userInfo.id
                    }
                })
                if (res.status === 200) {
                    this.couponList = res.data
                } else if (res.status === 557) {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            })
        }
    },
    data () {
        return {
            isTopwarn: false,
            topWarn: '',
            isdialog: false,
            navbarGroups: ['有效期', '已过期'],
            selectNavbar: 0,
            couponList: [],
            islogin: false,
            userInfo: {}
        }
    },
    computed: {
        filterCouponList: function () {
            if (this.selectNavbar === 0) {
                const list = this.couponList.filter(t => 60 * 60 * 24 * 1000 > this.compareTime(t.endtime) > 0)
                list.forEach((i) => {
                    i.expire = true
                })
                return this.couponList.filter(t => this.compareTime(t.endtime) > 0)
            } else {
                return this.couponList.filter(t => this.compareTime(t.endtime) < 0)
            }
        }
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        changeNavbar (index) {
            if (this.selectNavbar === index) return
            this.selectNavbar = index
        },
        compareTime (time) {
            const today = new Date()
            const date = new Date(time.replace(/-/g, '/'))
            return date.getTime() - today.getTime()
        },
        changeDialog () {
            this.isdialog = !this.isdialog
        },
        deleteCollect () {
            if (this.couponList.length > 0) {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.get('deleteCollect', {
                        params: {
                            userId: this.userInfo.id
                        }
                    })
                    if (res.status === 200) {
                        this.couponList = []
                        this.isdialog = !this.isdialog
                    } else if (res.status === 557) {
                        this.topWarn = res.msg
                        this.isTopwarn = true
                        setTimeout(() => {
                            this.isTopwarn = false
                            this.topWarn = ''
                        }, 2000)
                    }
                })
            } else {
                this.isdialog = !this.isdialog
            }
        },
        getKf () {
            this.$router.push('/home')
        }
    }
}
</script>
<style lang="less" scoped>
.coupon_tip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
    font-size: 14px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
}
.header_r {
    position: absolute;
    right: 5%;
}
.weui-navbar__item {
    padding: 9px 0;
    font-size: 14px;
    color: #444;
}
.gray_img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    pointer-events: none;
}
.coupon_time {
    margin-top: 5px;
    font-family: Arial, Helvetica, sans-serif;
}
.time {
    display: flex;
    margin: 0 10px;
    font-size: 12px;
    color: #999;
    i {
        color: salmon;
        font-style: normal;
        margin-left: 3px;
    }
}
.noorders {
    width: 100%;
    background: #fff;
    text-align: center;
    color: #333;
    padding-top: 50px;
}
</style>
