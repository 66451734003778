<template>
    <div class="home_container">
        <footer class="my_footer">
            <div class="footer_container white_color">
                <div class="weui-tabbar">
                    <router-link to="home" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_home">
                        </div>
                        <p class="weui-tabbar__label">首页</p>
                    </router-link>
                    <router-link to="classify" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_list">
                        </div>
                        <p class="weui-tabbar__label">分类</p>
                    </router-link>
                    <router-link to="search" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_search">
                        </div>
                        <p class="weui-tabbar__label">发现</p>
                    </router-link>
                    <div class="weui-tabbar__item weui-bar__item--on">
                        <div class="weui-tabbar__icon foot_menu_mine">
                        </div>
                        <p class="weui-tabbar__label">我的</p>
                    </div>
                </div>
            </div>
        </footer>
        <div class="main_container">
            <div class="white_color head_container clearfix">
                <div class="head_img">
                    <div class="head_avatar">
                        <img :src= "userInfo.avatar" alt="">
                    </div>
                    <div class="head_nick" v-if="this.islogin">{{ userInfo.username }}</div>
                    <div class="head_icon">
                        <router-link to="/version" class="icon_container red_point">
                            <img src="../assets/home-message.svg" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="head_tab" v-if="this.islogin">
                    <div class="weui-flex">
                        <router-link to="/gift" class="weui-flex__item">
                            <div class="tab_container">
                                <p>{{ this.extraInfo.countGift }}</p>
                                <span>我的礼品</span>
                            </div>
                        </router-link>
                        <router-link to="/collect" class="weui-flex__item">
                            <div class="tab_container">
                                <p>{{ this.extraInfo.countCollect }}</p>
                                <span>我的收藏</span>
                            </div>
                        </router-link>
                        <router-link to="/wallet" class="weui-flex__item">
                            <div class="tab_container">
                                <p><i class="money_h">￥</i>{{ this.extraInfo.money }}</p>
                                <span>我的钱包</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="content_container" v-if="this.islogin">
                <div class="orders_container weui-cells">
                    <router-link to="/orders" class="weui-cell weui-cell_access">
                        <div class="weui-cell__bd">
                            <p class="order_text">
                                <img src="../assets/home-1.svg" alt="">
                                全部订单
                            </p>
                        </div>
                        <div class="weui-cell__ft order_des">
                            查看所有订单
                        </div>
                    </router-link >
                </div>
                <div class="orders_tab weui-flex white_color">
                    <router-link :to="{ name: 'orders', query: { selectNavbar: 1 } }" class="weui-flex__item">
                        <div class="weui-flex__icon">
                            <img src="../assets/home-dfk.svg" alt="">
                        </div>
                        <p class="weui-flex__label">已付款</p>
                    </router-link>
                    <router-link :to="{ name: 'orders', query: { selectNavbar: 2 } }" class="weui-flex__item">
                        <div class="weui-flex__icon">
                            <img src="../assets/home-dsh.svg" alt="">
                        </div>
                        <p class="weui-flex__label">已收货</p>
                    </router-link>
                    <router-link :to="{ name: 'orders', query: { selectNavbar: 3 } }" class="weui-flex__item">
                        <div class="weui-flex__icon">
                            <img src="../assets/home-dfh.svg" alt="">
                        </div>
                        <p class="weui-flex__label">已结算</p>
                    </router-link>
                    <router-link :to="{ name: 'orders', query: { selectNavbar: 4 } }" class="weui-flex__item">
                        <div class="weui-flex__icon">
                            <img src="../assets/home-tk.svg" alt="">
                        </div>
                        <p class="weui-flex__label">已失效</p>
                    </router-link>
                </div>
                <!-- <div class="info_container weui-cells">
                    <router-link to="/myinfo" class="weui-cell weui-cell_access">
                        <div class="weui-cell__bd">
                            <p class="order_text">
                                <img src="../assets/home-4.svg" alt="">
                                淘宝授权
                            </p>
                        </div>
                        <div class="weui-cell__ft order_des">
                            <span v-if="!this.userInfo.relationId">淘宝授权绑定享特权</span>
                            <span v-if="this.userInfo.relationId">已获取特权</span>
                        </div>
                    </router-link>
                </div> -->
            </div>
            <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
            <div class="weui-toptips weui-toptips_success" :class="isTopsuccess?'opacitied':'noopacitied'">{{ this.topSuccess }}</div>
            <div :class="iswarntoast?'blocked':'noblocked'">
                <div class="weui-mask_transparent"></div>
                <div class="weui-toast">
                    <i class="weui-icon-warn weui-icon_toast"></i>
                    <p class="weui-toast__content">{{ this.warncontent }}</p>
                </div>
            </div>
            <div v-if="!this.islogin">
                <div class="weui-mask"></div>
                <div class="weui-dialog">
                    <div class="weui-dialog__bd">请添加客服群获取链接</div>
                    <div class="weui-dialog__ft">
                        <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.islogin) {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('getUserInfo')
                if (res.status === 200) {
                    this.extraInfo = res.data
                } else if (res.status === 557) {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            })
        }
    },
    activated () {},
    deactivated () {},
    data () {
        return {
            iswarntoast: false,
            warncontent: '即将开放',
            errorMsg: '',
            issend: false,
            times: 59,
            isTopsuccess: false,
            isTopwarn: false,
            topWarn: '',
            topSuccess: '',
            login_regist: false,
            extraInfo: {
                countCollect: 0,
                money: 0
            },
            // 用户登录信息
            islogin: false,
            userInfo: {}
        }
    },
    methods: {
        getKf () {
            this.$router.push('/home')
        }
    }
}
</script>
<style lang="less" scoped>
.bottom_height {
    width: 100%;
    position: relative;
    float: left;
    height: 62.8px;
}
.main_container {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    position: relative;
}
.weui-toptips_success {
    background-color: rgb(26, 161, 48);
}
// head
.head_img {
    position: relative;
    background: url(../assets/mine_bg.jpg) no-repeat center;
    background-size: 100%;
    width: 100%;
    height: 200px;
}
.head_avatar {
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    background: #fff;
    position: absolute;
    left: 24px;
    bottom: 0;
    margin-bottom: -40px;
}
.head_avatar img {
    width: 100%;
}
.head_nick {
    position: absolute;
    bottom: 3px;
    font-size: 15px;
    left: 120px;
    line-height: 30px;
    color: #fff;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
.head_icon {
    position: absolute;
    top: 7.5px;
    right: 7.5px;
}
.icon_container {
    margin-left: 5px;
    font-size: 18px;
    color: #fff;
    float: left;
}
.head_tab {
    width: 70%;
    float: right;
    text-align: center;
    padding: 8px 0;
}
.head_tab .weui-flex__item {
    border-right: 1px solid #eee;
}
.tab_container {
    color: #444;
    font-family: Arial, Helvetica, sans-serif;
}
.tab_container p {
    margin: 0;
    font-weight: bold;
    line-height: 12px;
    font-size: 14px;
}
.tab_container span {
    font-size: 12px;
}
.red_point {
    position: relative;
}
.red_point::before {
    content: " ";
    background: red;
    border: 3px solid red;
    border-radius:3px;
    position: absolute;
    z-index: 1000;
    right: 0;
}
// content_container
.content_container {
    background: #eee;
    padding-top: 7.5px;
    // -webkit-animation: bounceIn 2s ease .1s 1 both;
}
.orders_container {
    margin-top: 0;
    margin-bottom: 0;
}
.order_text {
    margin: 0;
    font-size: 15px;
    color: #444;
    height: 30px;
    line-height: 30px;
}
.order_des {
    font-size: 12px;
}
// orders_tab
.orders_tab {
    padding: 12px 0;
    border-top: 1px solid #f2f2f2;
    margin-bottom: 7.5px;
    margin-top: 0;
    background: #fff;
}
.orders_tab .weui-flex__item {
    text-align: center;
}
.orders_tab .weui-flex__label {
    margin: 0;
    color: #777;
    font-size: 12px;
}
// info_container
.info_container {
    margin-top: 0;
    margin-bottom: 0;
}
</style>
