<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">我的资料</div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_container">
            <div class="step_container">
                <el-steps :active="activeStep" process-status="process" finish-status="success" align-center>
                    <el-step title="授权绑定" icon="el-icon-check" description="用于获取订单信息"></el-step>
                    <el-step title="完成" icon="el-icon-star-off" description="购买分享，赚取佣金"></el-step>
                </el-steps>
            </div>
            <div class="get_code_container" v-if="this.activeStep === 0">
                <div class="get_code_text">长按保存，打开淘宝APP扫码授权</div>
                <div class="get_code_img">
                    <vue-qr :text="getcodeurl" :margin="0" colorDark="black" colorLight="#fff" :logoCornerRadius="1" :size="500" class="qc" />
                </div>
            </div>
            <div class="btn_container">
                <el-button class="step_btn" @click="isRelationId" v-if="this.activeStep === 0">下一步</el-button>
                <el-button class="step_btn" @click="goBack" v-if="this.activeStep === 1">完成</el-button>
            </div>
        </div>
        <div :class="iswarntoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast">
                <i class="weui-icon-warn weui-icon_toast"></i>
                <p class="weui-toast__content">{{ this.content }}</p>
            </div>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
        <div v-if="!this.islogin">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">请添加客服群获取链接</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.userInfo.relationId) {
            this.activeStep = 1
        }
        this.getcodeurl = this.getcodeurl + '&state=' + this.userInfo.id
    },
    data () {
        return {
            isTopwarn: false,
            topWarn: '',
            iswarntoast: false,
            content: '',
            islogin: false,
            userInfo: {},
            activeStep: 0,
            getcodeurl: 'https://oauth.taobao.com/authorize?response_type=code&client_id=32396137&redirect_uri=https://www.8y8w.cn/api/discount_gift_api/auth/&view=wap'
        }
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        isRelationId () {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('resetCookie')
                if (res.status === 200) {
                    this.userInfo = res.data
                    this.activeStep += 1
                } else if (res.status === 557) {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                } else {
                    this.content = res.msg
                    this.warnToast()
                }
            })
        },
        warnToast () {
            this.iswarntoast = true
            setTimeout(() => {
                    this.iswarntoast = false
                }, 1500)
        },
        getKf () {
            this.$router.push('/home')
        }
    }
}
</script>
<style lang="less" scoped>
.header_container {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}
.step_container {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
}
.get_code_container {
    width: 80%;
    margin: 10px auto;
}
.get_code_img {
    width: 270px;
    padding: 10px;
    margin: 0 auto;
}
.get_code_text {
    text-align: center;
    width: 100%;
}
.btn_container {
    position: fixed;
    bottom: 50px;
    width: 80%;
    left: 10%;
}
.step_btn {
    width: 100%;
}
.info_container {
    width: 95%;
    margin: 50px auto;
}
.qc {
    width: 100%;
    height: 100%;
}
</style>
