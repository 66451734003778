<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">我的订单</div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_container">
            <div class="weui-navbar">
                <div class="weui-navbar__item" :class="index === selectNavbar ? 'weui-bar__item--on' : ''" :key="item.id" v-for="(item, index) in navbarGroups" @click="changeNavbar(index)">{{ item }}</div>
            </div>
            <div class="orders_container">
                <ul>
                    <li :key="item.id" v-for="item in orderGroups">
                        <router-link :to="{ name: 'search', query: { title: item.title } }" class="order_container">
                            <div class="order_img">
                                <img :src="item.img" alt="">
                            </div>
                            <div class="order_info">
                                <div class="order_title">{{ item.title }}</div>
                                <div class="status_shop">
                                    <p class="float_right store_name">{{ item.shop }}</p>
                                    <p class="float_right order_status" v-if="item.status === 3">已结算</p>
                                    <p class="float_right order_status" v-if="item.status === 12">已付款</p>
                                    <p class="float_right order_status" v-if="item.status === 13">已失效</p>
                                    <p class="float_right order_status" v-if="item.status === 14">已收货</p>
                                    <span>
                                        付款金额
                                        <i class="default">￥{{ item.payprice }}</i> 付款预估收入 <i class="default">￥{{ item.payfee }}</i>
                                    </span>
                                    <span v-if="item.payendprice">
                                        结算金额
                                        <i class="default">￥{{ item.payendprice }}</i> 结算预估收入 <i class="orange">￥{{ item.payendfee }}</i>
                                    </span>
                                </div>
                                <div class="order_time clearfix">
                                    <span>
                                        付款时间 {{ item.paytime }}
                                    </span>
                                    <span v-if="item.payendtime">
                                        结算时间 {{ item.payendtime }}
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <div class="noorders" v-if="orderGroups.length === 0">无订单数据</div>
            </div>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
        <div v-if="!this.islogin">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">请添加客服群获取链接</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        if (this.$route.query.selectNavbar) {
            this.selectNavbar = this.$route.query.selectNavbar
        }
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.userInfo.relationId) {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.post('orders', {
                    relationId: this.userInfo.relationId,
                    status: this.statusGroups[this.selectNavbar]
                })
                if (res.status === 200) {
                    this.orderGroups = res.data
                } else if (res.status === 557) {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            })
        }
    },
    data () {
        return {
            isTopwarn: false,
            topWarn: '',
            islogin: false,
            userInfo: {},
            orderGroups: [],
            navbarGroups: ['全部', '已付款', '已收货', '已结算', '已失效'],
            selectNavbar: 0,
            statusGroups: ['', 12, 14, 3, 13]
        }
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        changeNavbar (index) {
            if (this.selectNavbar === index) return
            this.selectNavbar = index
            if (this.userInfo.relationId) {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.post('orders', {
                        relationId: this.userInfo.relationId,
                        status: this.statusGroups[index]
                    })
                    if (res.status === 200) {
                        this.orderGroups = res.data
                    } else if (res.status === 557) {
                        this.topWarn = res.msg
                        this.isTopwarn = true
                        setTimeout(() => {
                            this.isTopwarn = false
                            this.topWarn = ''
                        }, 2000)
                    }
                })
            }
        },
        getKf () {
            this.$router.push('/home')
        }
    }
}
</script>
<style lang="less" scoped>
.weui-navbar__item {
    padding: 9px 0;
    font-size: 14px;
    color: #444;
}
.orders_container {
    width: 100%;
    overflow: hidden;
    background: #eee;
}
.noorders {
    width: 100%;
    background: #fff;
    text-align: center;
    color: #333;
    padding-top: 50px;
}
.orders_container li {
    padding: 10px;
    transform-origin: center;
    background: #fff;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    zoom: 1;
    font-family: Arial, Helvetica, sans-serif;
}
.orders_container li::before {
    content: '';
    display: block;
}
.orders_container li::after {
    content: '';
    display: block;
}
.order_img {
    position: relative;
    width: 92px;
    height: 92px;
    margin-right: 10px;
    float: left;
}
.order_img img {
    background-color: #f5f5f5;
    display: block;
    width: 100%;
    height: 100%;
}
.order_info {
    height: 132px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    zoom: 1;
    top: 5.6px;
}
.order_title {
    line-height: 19.6px;
    font-size: 13px;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 39px;
    margin-right: 10px;
    margin-bottom: 0;
    overflow: hidden;
    color: #333;
}
.status_shop {
    font-size: 12px;
    height: 24px;
    color: #999;
}
.store_name {
    font-size: 10px;
    height: 14px;
    color: #a08f8f;
    max-width: 60%;
    display: -webkit-box;
    line-height: 14px;
    overflow: hidden;
    position: absolute;
    bottom: 5px;
    right: 0;
}
.order_status {
    font-size: 10px;
    height: 14px;
    color: #956BFF;
    max-width: 60%;
    display: -webkit-box;
    line-height: 14px;
    overflow: hidden;
    position: absolute;
    bottom: 25px;
    right: 0;
}
.status_shop span {
    font-weight: 400;
    font-size: 12px;
    margin-right: 7px;
    display: flex;
    color: #999;
    height: 20px;
    line-height: 20px;
}
.default {
    padding-right: 2px;
    font-size: 12px;
    font-style: normal;
    color: #333;
    margin-left: 2px;
    margin-right: 2px;
}
.order_time {
    margin-top: 29px;
}
.order_time span {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    color: #999;
}
.orange {
    color: #FF822E;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}
</style>
