<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">版本 / 通知</div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_container">
            <ul class="el-timeline">
                <li class="el-timeline-item">
                    <div class="el-timeline-item__tail"></div>
                    <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--"></div>
                    <div class="el-timeline-item__wrapper">
                        <div class="el-timeline-item__timestamp is-top">
                            2021-4-2
                        </div>
                        <div class="el-timeline-item__content">
                            <el-card>
                                <h4>通知</h4>
                                <p style="color: red">请勿使用其他红包，否则将无法获得佣金收益</p>
                                <p>佣金受结算金额影响，以实际打款金额为准</p>
                                <p>有任何疑问可添加微信客服</p>
                            </el-card>
                        </div>
                    </div>
                </li>
                <li class="el-timeline-item">
                    <div class="el-timeline-item__tail"></div>
                    <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--"></div>
                    <div class="el-timeline-item__wrapper">
                        <div class="el-timeline-item__timestamp is-top">
                            2021-4-1
                        </div>
                        <div class="el-timeline-item__content">
                            <el-card>
                                <h4>版本 1.0</h4>
                                <p>商家福利：精选淘宝优惠券</p>
                            </el-card>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goBack () {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.main_container {
    width: 100%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    p {
        font-size: 12px;
    }
    h4 {
        margin-bottom: 2px;
    }
}
</style>
