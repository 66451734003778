<template>
    <div class="home_container">
        <div class="d_blank"></div>
        <div class="header_container">
            <div class="el-page-header">
                <div class="el-page-header__left" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div class="el-page-header__title">返回</div>
                </div>
                <div class="el-page-header__content">礼品专区</div>
                <div class="header_right home_header_right">
                    <span class="btndh lijin" @click="openYq">礼金</span>
                </div>
            </div>
            <div class="d_blank_row"></div>
        </div>
        <div class="main_cointainer">
            <div class="weui-navbar">
                <div class="weui-navbar__item" :class="index === selectNavbar ? 'weui-bar__item--on' : ''" :key="item.id" v-for="(item, index) in navbarGroups" @click="changeNavbar(index)">{{ item }}</div>
            </div>
            <div class="coupons_container" v-if="selectNavbar === 0">
                <div class="coupon_container" :key="item.id" v-for="item in filterGift" :class="item.count === 0?'gray_img':''">
                    <div class="coupon">
                        <div class="coupon_position">
                            <div class="coupon_img">
                                <img :src="item.pic" alt="">
                            </div>
                            <div class="coupon_name tcenter">{{ item.name }}</div>
                            <div class="coupon_btn">
                                <el-button size="small" type="warning" style="width: 100%" v-if="item.count > 0" @click="applyGift(item)">立即申请</el-button>
                                <el-button size="small" type="warning" style="width: 100%" v-if="item.count === 0">已抢完</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coupon_container gray_img" :key="item.id" v-for="item in filterGift2">
                    <div class="coupon">
                        <div class="coupon_position">
                            <div class="coupon_img">
                                <img :src="item.pic" alt="">
                            </div>
                            <div class="coupon_name tcenter">{{ item.name }}</div>
                            <div class="coupon_btn">
                                <el-button size="small" type="warning" style="width: 100%">已抢完</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="noorders" v-if="giftList.length === 0">暂无礼品</div>
            </div>
            <div class="gifts_container" v-if="selectNavbar === 1 && isdetail === false">
                <ul>
                    <li :key="item.id" v-for="item in user2task_list" @click="chooseDetail(item.user2task)" :class="item.user2task.status === 9?'gray_img':(item.user2task.status > 7 ? 'success': '')">
                        <div class="gift_img">
                            <img :src= item.gift.pic alt="">
                        </div>
                        <div class="gift_info">
                            <div class="info_title">{{ item.gift.name }}</div>
                            <div class="info_time">
                                <p class="float_right status" v-if="item.user2task.status < 8">待购买</p>
                                <p class="float_right status" v-if="item.user2task.status === 8">已提交</p>
                                <p class="float_right status" v-if="item.user2task.status === 9">已放弃</p>
                                <p class="float_right status" v-if="item.user2task.status === 10">已收货</p>
                                <p class="float_right status" v-if="item.user2task.status === 11">已评价</p>
                                <p class="float_right status" v-if="item.user2task.status === 12">已退货</p>
                                <span>创建时间: {{ item.user2task.createtime }} </span>
                                <span>更新时间: {{ item.user2task.updatetime }} </span>
                            </div>
                        </div>
                    </li>
                    <div class="noorders" v-if="user2task_list.length === 0">暂无礼品</div>
                </ul>
            </div>
            <div class="apply_container" v-if="selectNavbar === 1 && isdetail === true">
                <div class="gift_container" v-if="step === 0">
                    <div class="gift_step">
                        <span class="step_top">请前往淘宝APP搜索下方关键词，并找到与下方图片一致的商品</span>
                        <span class="step_tip">如有疑问，请点击 <p @click="isshow = 1">如何搜索</p></span>
                    </div>
                    <div class="blank_row"></div>
                    <div class="gift_store">
                        购物平台: <span class="platform">{{ detailinfo.platform }}</span>
                    </div>
                    <div class="gift_store clearfix">
                        关键词：<span class="keyw">{{ detailinfo.key }}</span>
                        <el-button type="primary" size="mini" class="store_btn" @click="changeKey" v-if="detailinfo.keyword.length > 1">换一个</el-button>
                    </div>
                    <div class="gift_store" v-if="detailinfo.filter">
                        筛选条件: <span class="filter">{{ detailinfo.filter }}</span>
                    </div>
                    <div class="gift_store" v-if="detailinfo.tip.length > 0">
                        购物须知: <div class="attention_container">
                            <span class="attention" :key="item.id" v-for="item in detailinfo.tip">{{ item }}</span>
                        </div>
                    </div>
                    <div class="gift_pic2" v-if="detailinfo.pic.length > 1">
                        <img :src= item alt="" :key="item.id" v-for="item in detailinfo.pic">
                    </div>
                    <div class="gift_pic3" v-if="detailinfo.pic.length === 1">
                        <img :src= detailinfo.pic alt="">
                    </div>
                    <div class="btns">
                        <el-button class="btn" type="primary" round @click="backUsertaskList">返回</el-button>
                        <el-button class="btn" type="danger" round @click="changeAbandon">放弃</el-button>
                        <el-button class="btn" type="success" round @click="addStep">找到了</el-button>
                    </div>
                </div>
                <div class="gift_container" v-if="step === 1">
                    <div class="gift_step">
                        <span class="step_top">请进入商品详情页浏览商品后，回答下方问题，验证所找的商品是否正确。</span>
                        <span class="step_tip">如有疑问，请点击 <p @click="isshow = 2">商品标题在哪里？</p></span>
                    </div>
                    <div class="blank_row"></div>
                    <div class="radio_container">
                        <div class="radio_title">(1/2)商品标题最后2个字是什么？</div>
                        <div class="option_container">
                            <el-button class="option_button" :key="item.id" v-for="item in optionList1" @click="chooseOption(item)">{{ item }}</el-button>
                        </div>
                    </div>
                    <div class="mistake_container" v-if="ismistake">
                        <div class="mistake_tip">很遗憾，回答错误！等待15s后再重新选择~</div>
                    </div>
                    <div class="btns">
                        <el-button class="btn" type="primary" round @click="substractStep">返回</el-button>
                        <el-button class="btn" type="danger" round @click="changeAbandon">放弃</el-button>
                        <el-button class="btn" type="success" round @click="addStep" v-if="!ismistake && selectOption1">下一题</el-button>
                        <el-button class="btn" type="success" round v-if="!selectOption1 && !ismistake" disabled>下一题</el-button>
                        <el-button class="btn" type="success" round v-if="ismistake" disabled>下一题({{ times }})</el-button>
                    </div>
                </div>
                <div class="gift_container" v-if="step === 2">
                    <div class="gift_step">
                        <span class="step_top">请进入商品详情页浏览商品后，回答下方问题，验证所找的商品是否正确。</span>
                        <span class="step_tip">如有疑问，请点击 <p @click="isshow = 3">店铺名在哪里？</p></span>
                    </div>
                    <div class="blank_row"></div>
                    <div class="radio_container">
                        <div class="radio_title">(2/2)店铺名的第4个字是什么？</div>
                        <div class="option_container">
                            <el-button class="option_button" :key="item.id" v-for="item in optionList2" @click="chooseOption2(item)">{{ item }}</el-button>
                        </div>
                    </div>
                    <div class="mistake_container" v-if="ismistake">
                        <div class="mistake_tip">很遗憾，回答错误！等待15s后再重新选择~</div>
                    </div>
                    <div class="btns">
                        <el-button class="btn" type="primary" round @click="substractStep">返回</el-button>
                        <el-button class="btn" type="danger" round @click="changeAbandon">放弃</el-button>
                        <el-button class="btn" type="success" round @click="addStep" v-if="!ismistake && selectOption2">提交答案</el-button>
                        <el-button class="btn" type="success" round v-if="!selectOption2 && !ismistake" disabled>提交答案</el-button>
                        <el-button class="btn" type="success" round v-if="ismistake" disabled>提交答案({{ times }})</el-button>
                    </div>
                </div>
                <div class="gift_container" v-if="step === 3">
                    <div class="gift_step">
                        <span class="step_top">从淘宝APP点击【分享】至微信，回到此页面，点击验证淘口令</span>
                        <span class="step_tip">如有疑问，请点击 <p @click="isshow = 4">如何验证</p></span>
                    </div>
                    <div class="blank_row"></div>
                    <div class="input_container">
                        <el-input type="textarea" autosize placeholder="请将淘口令粘贴到此处后，点击验证淘口令" v-model="textarea1"></el-input>
                    </div>
                    <div class="btns">
                        <el-button class="btn" type="primary" round @click="substractStep">返回</el-button>
                        <el-button class="btn" type="danger" round @click="changeAbandon">放弃</el-button>
                        <el-button class="btn" type="success" round @click="addStep" v-if="textarea1">验证淘口令</el-button>
                        <el-button class="btn" type="success" round v-if="!textarea1" disabled>验证淘口令</el-button>
                    </div>
                </div>
                <div class="gift_container" v-if="step === 4">
                    <div class="gift_step">
                        <span class="step_top">返回淘宝APP进行付款后，复制订单编号，回到此页面，点击提交订单</span>
                        <span class="step_tip">如有疑问，请点击 <p @click="isshow = 5">订单号在哪里</p></span>
                    </div>
                    <div class="blank_row"></div>
                    <div class="submit_container">
                        <div class="submit_info">
                            购买数量<span>{{ detailinfo.num }}</span>
                        </div>
                        <div class="submit_info">
                            <p class="submit_info_p">【按实付金额返款】</p>先付款<span>￥{{ detailinfo.money }}</span>
                        </div>
                        <div class="submit_info" v-if="detailinfo.comment === '0'">
                            <p class="submit_info_p">【收货】</p>后返利<span>￥{{ detailinfo.money }}</span>
                        </div>
                        <div class="submit_info" v-if="detailinfo.comment === '1'">
                            【好评】后返利<span>￥{{ detailinfo.money }}</span>
                        </div>
                        <div class="submit_info">
                            返利方式<i class="el-icon-info" style="margin-left: 2px" @click="istip = true"></i><span>微信提现</span>
                        </div>
                    </div>
                    <div class="blank_row"></div>
                    <div class="submit_input_container">
                        <div class="submit_input_title">订单编号</div>
                        <el-input class="submit_input" placeholder="请将订单编号填于此处，点击提交订单" v-model="trade" type="number"></el-input>
                    </div>
                    <div class="attention_container">
                        <div class="attention_show" @click="isattention = true"><i class="el-icon-s-opportunity"></i>注意事项</div>
                    </div>
                    <div class="btns">
                        <el-button class="btn" type="primary" round @click="backUsertaskList">返回</el-button>
                        <el-button class="btn" type="danger" round @click="changeAbandon">放弃</el-button>
                        <el-button class="btn" type="success" round @click="submitTrade" v-if="trade">提交订单</el-button>
                        <el-button class="btn" type="success" round v-if="!trade" disabled>提交订单</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div :class="iswarntoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast">
                <i class="weui-icon-warn weui-icon_toast"></i>
                <p class="weui-toast__content">{{ this.warncontent }}</p>
            </div>
        </div>
        <div :class="issuccesstoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast">
                <i class="weui-icon-success-no-circle weui-icon_toast"></i>
                <p class="weui-toast__content">{{ this.warncontent }}</p>
            </div>
        </div>
        <div v-if="!this.islogin">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">请添加客服群获取链接</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="getKf">立即前往</a>
                </div>
            </div>
        </div>
        <div v-if="this.istip">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">收到礼品后，请前往淘宝APP确认收货，平台确认后将自动返利至余额，前往钱包即可提现至微信</div>
                <div class="weui-dialog__ft">
                    <a href="javascript:" class="weui-dialog__btn weui-dialog__btn_primary" @click="istip = false">我知道了</a>
                </div>
            </div>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
        <div class="weui-toptips weui-toptips_success" :class="isTopsuccess?'opacitied':'noopacitied'">{{ this.topSuccess }}</div>
        <div>
            <div class="weui-mask" @click="changeAbandon" :class="isAbandon?'opacitied':'noopacitied'"></div>
            <div id="js_dialog_2" class="weui-half-screen-dialog" :class="isAbandon?'weui-half-screen-dialog_show':''">
                <div class="weui-half-screen-dialog__hd">
                  <div class="weui-half-screen-dialog__hd__side">
                    <button style="display: none;" class="weui-icon-btn">返回<i class="weui-icon-back-arrow-thin"></i></button>
                    <button class="weui-icon-btn" @click="changeAbandon">关闭<i class="weui-icon-close-thin"></i></button>
                  </div>
                  <div class="weui-half-screen-dialog__hd__main">
                    <strong class="weui-half-screen-dialog__title">放弃</strong>
                    <!-- <span class="weui-half-screen-dialog__subtitle">标题</span> -->
                  </div>
                  <!-- <div class="weui-half-screen-dialog__hd__side">
                    <button class="weui-icon-btn">更多<i class="weui-icon-more"></i></button>
                  </div> -->
                </div>
                <div class="weui-half-screen-dialog__bd">
                  <!-- <p class="weui-half-screen-dialog__desc">
                      确认放弃吗？
                  </p>
                  <p class="weui-half-screen-dialog__tips"> -->
                    <el-select class="good_input" v-model="value" placeholder="请选择放弃理由">
                        <el-option
                        v-for="item in option"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  <!-- </p> -->
                </div>
                <div class="weui-half-screen-dialog__ft">
                    <a href="javascript:" class="weui-btn weui-btn_default" @click="changeAbandon">取消</a>
                    <a href="javascript:" class="weui-btn weui-btn_primary" @click="abandonTask">确认放弃</a>
                </div>
            </div>
        </div>
        <div class="show_img" v-if="isshow > 0" @click="isshow = 0">
            <img src="../assets/step-1.png" alt="" v-if="isshow === 1">
            <img src="../assets/step-2.png" alt="" v-if="isshow === 2">
            <img src="../assets/step-3.png" alt="" v-if="isshow === 3">
            <img src="../assets/step-4.png" alt="" v-if="isshow === 4">
            <img src="../assets/step-5.png" alt="" v-if="isshow === 5">
        </div>
        <div class="show_attention" v-if="isattention">
            <div class="weui-mask">
                <div class="my-dialog">
                    <div class="weui-dialog__bd">
                        <div class="attention_title">注意事项</div>
                        <div class="attention_list">
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-s-ticket"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">不使用其他优惠</div>
                                    <div class="item_tip">除平台返利及指定领取的优惠券，不得使用淘宝客、支付宝红包、淘金币、淘宝购物津贴等折扣形式</div>
                                </div>
                            </div>
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-error"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">退款退返利</div>
                                    <div class="item_tip">买家享受正常售后保障，但是退款前须退还返利</div>
                                </div>
                            </div>
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-warning"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">返利金额不变</div>
                                    <div class="item_tip">请按照提示购买指定预付金额的商品，若成交价高于指定价格，返利金额固定不变</div>
                                </div>
                            </div>
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-s-comment"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">不得提及返利</div>
                                    <div class="item_tip">不得在评价或者联系商家客服时提及跟返利有关的事情</div>
                                </div>
                            </div>
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-s-release"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">不支持开票</div>
                                    <div class="item_tip">平台所有返利商品均不支持开票，请勿咨询商家关于开票的事情</div>
                                </div>
                            </div>
                            <div class="attention_item">
                                <div class="item_img">
                                    <i class="el-icon-message-solid"></i>
                                </div>
                                <div class="item_info">
                                    <div class="item_subtitle">违反处罚</div>
                                    <div class="item_tip">请遵守本注意事项，否则平台有权根据损失情况对用户进行处罚</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="weui-dialog__ft" @click="isattention = false">
                        <a href="javascript:" class="weui-dialog__btn weui-dialog__btn_primary">知道了</a>
                    </div>
                </div>
            </div>
        </div>
        <div :class="ischeckdialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <span style="color: red">将*号部分改为您实际收货信息</span>
                    <div class="icon-box">
                        姓名<el-input v-model="checkname"></el-input>
                        电话<el-input v-model="checkphone"></el-input>
                        详细地址<el-input type="textarea" v-model="checkaddress"></el-input>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="closeCheckDialog">取消</a>
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="submitTrade">确认</a>
                </div>
            </div>
        </div>
        <div class="activity_container" v-if="isyq">
            <div class="kf_box">
                <div class="kf_title">邀请有礼</div>
                <div class="kf_content clearfix">
                    <div class="kf" v-if="!this.isbind" @click="doCopy">
                        点击复制邀请码<el-input v-model="my_yqm" disabled></el-input>
                        <span>邀请好友每完成一单可获得1元礼金</span>
                    </div>
                    <div class="kf" v-if="this.isbind">
                        请输入邀请码<el-input v-model="yqm" placeholder="复制邀请码粘贴于此"></el-input>
                        <div class="yq_button">
                            <el-button type="success" @click="bindYq" size="mini">确认</el-button>
                        </div>
                        <span>邀请好友每完成一单可获得1元礼金</span>
                    </div>
                </div>
                <div class="kf_button">
                    <el-button type="primary" @click="closeYq" size="mini">关闭窗口</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.userinfo = userCookie
            this.islogin = true
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('getGift')
                if (res.status === 200) {
                    this.giftList = res.data
                } else {
                    this.giftList = []
                }
            })
            this.$nextTick(async function () {
                const { data: res2 } = await this.$http.get('isBind')
                if (res2.status === 200) {
                    this.isbind = true
                } else {
                    this.isbind = false
                    this.my_yqm = res2.msg
                }
            })
        }
    },
    data () {
        return {
            uploadUrl: this.$http.defaults.baseURL + '/checkPic',
            option: [{
                value: '1',
                label: '不想做了'
            }, {
                value: '2',
                label: '找不到'
            }, {
                value: '3',
                label: '其他'
            }],
            value: '',
            isdetail: false,
            detailinfo: {
                store: '',
                keyword: [],
                key: '',
                trade: '',
                status: '',
                comment: '',
                platform: '',
                title: '',
                filter: '',
                tip: [],
                pic: [],
                money: '',
                createtime: ''
            },
            navbarGroups: ['礼品申请', '我的礼品'],
            selectNavbar: 0,
            giftList: [],
            iswarntoast: false,
            isyq: true,
            isbind: '',
            yqm: '',
            my_yqm: '',
            warncontent: '',
            issuccesstoast: false,
            isTopwarn: false,
            topWarn: '',
            isTopsuccess: false,
            topSuccess: '',
            gift: '',
            userinfo: {},
            islogin: false,
            addressList: [],
            selectedOptions: [],
            user2task: {},
            user2task_list: [],
            goods_link: '',
            isAbandon: false,
            step: 0,
            optionList1: [],
            selectOption1: '',
            ismistake: false,
            times: 15,
            optionList2: [],
            selectOption2: '',
            textarea1: '',
            trade: '',
            isshow: 0,
            istip: false,
            isattention: false,
            isapply: false,
            ischeckdialog: false,
            checkname: '',
            checkphone: '',
            checkaddress: '',
            chineseWord: ['见', '明', '问', '主', '发', '因', '品', '动', '东', '南', '西', '被', '备', '为', '设', '定', '力', '理', '上', '工',
             '巴', '斯', '视', '星', '画', '传', '空', '马', '月', '罗', '语', '科', '精', '极', '集', '解', '长', '认', '流', '通', '管', '曾',
             '核', '造', '专', '局', '考', '导', '倒', '半', '班', '梦', '萌', '黑', '白', '掉', '香']
        }
    },
    computed: {
        filterGift: function () {
            return this.giftList.filter(t => t.count > 0)
        },
        filterGift2: function () {
            return this.giftList.filter(t => t.count === 0)
        }
    },
    methods: {
        closeCheckDialog () {
            this.ischeckdialog = false
            this.checkname = ''
            this.checkphone = ''
            this.checkaddress = ''
        },
        goBack () {
            this.$router.go(-1)
        },
        getKf () {
            this.$router.push('/home')
        },
        openYq () {
            this.isyq = true
        },
        closeYq () {
            this.isyq = false
        },
        doCopy () {
            this.$copyText(this.my_yqm).then(
                this.issuccesstoast = true,
                this.warncontent = '复制成功'
            )
            setTimeout(() => {
                this.issuccesstoast = false
            }, 1500)
        },
        bindYq () {
            if (!this.yqm) {
                this.iswarntoast = true
                this.warncontent = '请输入邀请码'
                setTimeout(() => {
                    this.iswarntoast = false
                }, 2000)
            } else {
                this.$nextTick(async function () {
                const { data: res } = await this.$http.get('bindYqm', {
                    params: {
                        yqm: this.yqm
                    }
                })
                if (res.status === 200) {
                    this.$nextTick(async function () {
                        const { data: res2 } = await this.$http.get('isBind')
                        if (res2.status === 200) {
                            this.isbind = true
                        } else {
                            this.isbind = false
                            this.my_yqm = res2.msg
                        }
                    })
                    this.issuccesstoast = true
                    this.warncontent = '绑定成功'
                    setTimeout(() => {
                        this.issuccesstoast = false
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                } else {
                    this.iswarntoast = true
                    this.warncontent = res.msg
                    setTimeout(() => {
                        this.iswarntoast = false
                    }, 2000)
                }
            })
            }
        },
        changeNavbar (index) {
            if (this.selectNavbar === index) return
            this.selectNavbar = index
            if (index === 1) {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.get('getUser2task')
                    if (res.status === 200) {
                        this.user2task_list = res.data.reverse()
                    }
                })
            } else {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.get('getGift')
                    if (res.status === 200) {
                        this.giftList = res.data
                    } else {
                        this.giftList = []
                    }
                })
            }
        },
        applyGift (item) {
            if (this.isapply) {
                return
            }
            this.isapply = true
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('applyGift', {
                    params: {
                        giftId: item.id
                    }
                })
                if (res.status === 200) {
                    this.isapply = false
                    this.changeNavbar(1)
                    this.issuccesstoast = true
                    this.warncontent = '申请成功'
                    this.topSuccess = '请在30分钟内完成，超时自动放弃'
                    this.isTopsuccess = true
                    setTimeout(() => {
                        this.issuccesstoast = false
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                } else {
                    this.isapply = false
                    if (res.msg === '更新啦') {
                        this.$nextTick(async function () {
                            const { data: res } = await this.$http.get('getGift')
                            if (res.status === 200) {
                                this.giftList = res.data
                            } else {
                                this.giftList = []
                            }
                        })
                    }
                    this.iswarntoast = true
                    this.warncontent = res.msg
                    setTimeout(() => {
                        this.iswarntoast = false
                    }, 2000)
                }
            })
        },
        chooseDetail (user2task) {
            this.isdetail = true
            this.value = ''
            this.detailinfo.status = user2task.status
            this.detailinfo.user2taskid = user2task.id
            this.detailinfo.taskid = user2task.taskid
            this.detailinfo.trade = user2task.trade
            this.detailinfo.createtime = user2task.createtime
            if (this.detailinfo.status === 4 | this.detailinfo.status === 7) {
                this.step = 4
            } else {
                this.step = 0
            }
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('getTask', {
                    params: {
                        taskId: user2task.taskid
                    }
                })
                if (res.status === 200) {
                    this.detailinfo.keyword = res.data.keyword.split(',')
                    this.detailinfo.key = this.detailinfo.keyword[Math.floor(Math.random() * this.detailinfo.keyword.length)]
                    this.detailinfo.link = res.data.link
                    this.detailinfo.pic = res.data.pic.split(',')
                    this.detailinfo.store = res.data.store
                    if (res.data.tip) {
                        this.detailinfo.tip = res.data.tip.split(',')
                    }
                    this.detailinfo.title = res.data.title
                    this.detailinfo.platform = res.data.platform
                    this.detailinfo.filter = res.data.filter
                    this.detailinfo.money = res.data.money
                    this.detailinfo.num = res.data.num
                    this.detailinfo.comment = res.data.comment
                }
            })
        },
        backUsertaskList () {
            this.isdetail = false
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('getUser2task')
                if (res.status === 200) {
                    this.user2task_list = res.data.reverse()
                }
            })
        },
        changeKey () {
            this.detailinfo.key = this.detailinfo.keyword[Math.floor(Math.random() * this.detailinfo.keyword.length)]
        },
        submitTrade () {
            if (this.trade.length < 18 | this.trade.length > 25) {
                this.iswarntoast = true
                this.warncontent = '请输入正确的订单编号'
                setTimeout(() => {
                    this.iswarntoast = false
                }, 2000)
                return
            }
            if (this.checkname.indexOf('*') !== -1) {
                this.isTopwarn = true
                this.topWarn = '请修改正确的收货人姓名'
                setTimeout(() => {
                    this.isTopwarn = false
                }, 2000)
                return
            }
            if (this.checkphone.indexOf('*') !== -1) {
                this.isTopwarn = true
                this.topWarn = '请修改正确的收货人电话'
                setTimeout(() => {
                    this.isTopwarn = false
                }, 2000)
                return
            }
            if (this.checkaddress.indexOf('*') !== -1) {
                this.isTopwarn = true
                this.topWarn = '请修改正确的收货人地址'
                setTimeout(() => {
                    this.isTopwarn = false
                }, 2000)
                return
            }
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('submitTrade', {
                    params: {
                        user2taskId: this.detailinfo.user2taskid,
                        trade: this.trade,
                        name: this.checkname,
                        phone: this.checkphone,
                        address: this.checkaddress
                    }
                })
                if (res.status === 200) {
                    this.backUsertaskList()
                    this.closeCheckDialog()
                    this.topSuccess = '提交成功'
                    this.isTopsuccess = true
                    setTimeout(() => {
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                } else if (res.status === 500) {
                    if (res.msg === '校验失败或已放弃' || res.msg === '任务超时，即将自动放弃') {
                        this.backUsertaskList()
                    }
                    this.iswarntoast = true
                    this.warncontent = res.msg
                    setTimeout(() => {
                        this.iswarntoast = false
                    }, 2000)
                } else {
                    this.ischeckdialog = true
                    this.checkaddress = res.data.receiverAddress
                    this.checkname = res.data.name
                    this.checkphone = res.data.receiverMobile
                }
            })
        },
        changeAbandon () {
            this.isAbandon = !this.isAbandon
        },
        abandonTask () {
            if (this.value) {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.get('abandonTask', {
                        params: {
                            user2taskId: this.detailinfo.user2taskid,
                            reason: this.value
                        }
                    })
                    if (res.status === 200) {
                        this.topSuccess = '放弃成功'
                        this.isTopsuccess = true
                        setTimeout(() => {
                            this.topSuccess = ''
                            this.isTopsuccess = false
                        }, 2000)
                        this.isAbandon = false
                        this.backUsertaskList()
                    } else {
                        this.iswarntoast = true
                        this.warncontent = res.msg
                        setTimeout(() => {
                            this.iswarntoast = false
                        }, 2000)
                        this.isAbandon = false
                        this.backUsertaskList()
                    }
                })
            } else {
                this.isAbandon = true
                this.topWarn = '请选择放弃理由'
                this.isTopwarn = true
                setTimeout(() => {
                    this.isTopwarn = false
                    this.topWarn = ''
                }, 2000)
            }
        },
        addStep () {
            if (this.step === 0) {
                if (this.detailinfo.status === 0 | this.detailinfo.status === 2) {
                    this.handleLink()
                } else if (this.detailinfo.status === 1 | this.detailinfo.status === 3 | this.detailinfo.status === 5) {
                    this.step = 2
                    this.handleLink2()
                    return
                } else if (this.detailinfo.status === 4 | this.detailinfo.status === 7) {
                    this.step = 4
                    return
                } else if (this.detailinfo.status === 6) {
                    this.step = 3
                    return
                }
            } else if (this.step === 1) {
                if (this.selectOption1 === this.detailinfo.title.slice(this.detailinfo.title.length - 2, this.detailinfo.title.length)) {
                    this.handleLink2()
                    if (this.detailinfo.status === 0) {
                        this.checkInfo(1)
                    } else {
                        this.checkInfo(3)
                    }
                    this.topSuccess = '校验成功'
                    this.isTopsuccess = true
                    setTimeout(() => {
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                } else {
                    if (this.detailinfo.status === 2) {
                        this.step = 3
                        this.checkInfo(6)
                        return
                    }
                    this.ismistake = true
                    this.handleLink()
                    this.checkInfo(2)
                    setTimeout(() => {
                        this.ismistake = false
                    }, 15000)
                    const timer = setInterval(() => {
                        this.times -= 1
                        if (this.times === 0) {
                            clearInterval(timer)
                        }
                    }, 1000)
                    this.selectOption1 = ''
                    return
                }
            } else if (this.step === 2) {
                if (this.selectOption2 === this.detailinfo.store.slice(3, 4)) {
                    this.checkInfo(4)
                    this.topSuccess = '校验成功'
                    this.isTopsuccess = true
                    setTimeout(() => {
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                    this.step = 4
                    return
                } else {
                    if (this.detailinfo.status === 3 | this.detailinfo.status === 5) {
                        this.step = 3
                        this.checkInfo(6)
                        return
                    }
                    this.ismistake = true
                    this.handleLink2()
                    this.checkInfo(5)
                    setTimeout(() => {
                        this.ismistake = false
                    }, 15000)
                    const timer = setInterval(() => {
                        this.times -= 1
                        if (this.times === 0) {
                            clearInterval(timer)
                        }
                    }, 1000)
                    this.selectOption2 = ''
                    return
                }
            } else if (this.step === 3) {
                if (this.textarea1.trim().split('  ')[this.textarea1.trim().split('  ').length - 1] === this.detailinfo.link) {
                    this.checkInfo(7)
                    this.topSuccess = '校验成功'
                    this.isTopsuccess = true
                    setTimeout(() => {
                        this.topSuccess = ''
                        this.isTopsuccess = false
                    }, 2000)
                } else {
                    this.topWarn = '验证错误'
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                    return
                }
            }
            this.selectOption1 = ''
            this.selectOption2 = ''
            this.step += 1
        },
        checkInfo (status) {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.get('checkInfo', {
                    params: {
                        user2taskId: this.detailinfo.user2taskid,
                        status: status
                    }
                })
                if (res.status === 200) {
                    this.detailinfo.status = status
                }
            })
        },
        substractStep () {
            this.selectOption1 = ''
            this.selectOption2 = ''
            this.step = 0
        },
        chooseOption (item) {
            this.selectOption1 = item
        },
        chooseOption2 (item) {
            this.selectOption2 = item
        },
        handleLink () {
            this.optionList1 = []
            this.optionList1.push(this.detailinfo.title.slice(this.detailinfo.title.length - 2, this.detailinfo.title.length))
            for (var j = 0; j < 3; j++) {
                this.optionList1.push(this.detailinfo.title.slice(this.detailinfo.title.length - 2, this.detailinfo.title.length - 1) + this.getRandomChineseWord())
            }
            for (var i = 0; i < this.optionList1.length + 10; i++) {
                var rdm = Math.floor(Math.random() * this.optionList1.length)
                this.optionList1.push(this.optionList1[rdm])
                this.optionList1.splice(rdm, 1)
            }
        },
        handleLink2 () {
            this.optionList2 = []
            this.optionList2.push(this.detailinfo.store.slice(3, 4))
            for (var j = 0; j < 3; j++) {
                this.optionList2.push(this.getRandomChineseWord())
            }
        },
        getRandomChineseWord () {
            // var _randomUniCode = Math.floor(Math.random() * (40869 - 19968) + 19968).toString(16)
            // var str = '\\u' + _randomUniCode
            // str = str.replace(/\\/g, '%')
            // str = unescape(str)
            // str = str.replace(/%/g, '\\')
            var str = this.chineseWord[Math.floor(Math.random() * this.chineseWord.length)]
            return str
        }
    }
}
</script>
<style lang="less" scoped>
.weui-toptips_success {
    background-color: rgb(26, 161, 48);
}
.weui-navbar__item {
    padding: 9px 0;
    font-size: 14px;
    color: #444;
}
.coupons_container {
    background-color: #eee;
}
.apply_container {
    padding-top: 5px;
}
.coupons_container>div:nth-child(2n+1) {
    margin-right: 1%;
}
.coupon_container {
    background-color: #fff;
}
.coupon_name {
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    color: #999;
}
.coupon_img img {
    width: 100%;
    max-height: 156.8px;
}
.coupon_btn {
    margin: 2px 5px;
}
.tcenter {
    text-align: center;
}
.noorders {
    width: 100%;
    background: #fff;
    text-align: center;
    color: #333;
    padding-top: 50px;
}
.gift_pic {
    width: 30%;
    margin: 0 auto;
}
.gift_pic2 {
    margin: 0 auto;
}
.gift_pic3 {
    width: 45%;
    margin: 0 auto;
}
.gift_pic img {
    width: 100%;
    height: 100%;
}
.gift_pic2 img {
    width: 45%;
    height: 100%;
    margin: 0 2.5% 80px 2.5%;
}
.gift_pic3 img {
    width: 100%;
    height: 100%;
    margin-bottom: 80px;
}
.gift_title {
    width: 100%;
    div {
        color: #999;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }
}
.btn_container {
    margin: 20px;
}
.step_btn {
    width: 100%;
}
.info_container {
    width: 95%;
    margin: 10px auto;
}
.gifts_container {
    width: 100%;
    overflow: hidden;
    background: #eee;
    li {
        padding: 10px;
        transform-origin: center;
        background: #fff;
        margin: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        zoom: 1;
        font-family: Arial, Helvetica, sans-serif;
    }
}
.gift_img {
    position: relative;
    width: 92px;
    height: 92px;
    margin-right: 10px;
    float: left;
    img {
        background: #f5f5f5;
        display: block;
        width: 100%;
        height: 100%;
    }
}
.gift_info {
    height: 92px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    zoom: 1;
    top: 5.6px;
}
.info_title {
    line-height: 19.6px;
    font-size: 13px;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-right: 10px;
    margin-bottom: 0;
    overflow: hidden;
    color: #333;
}
.info_time {
    font-size: 12px;
    height: 24px;
    color: #999;
    span {
        font-weight: 400;
        font-size: 12px;
        margin-right: 7px;
        display: flex;
        color: #999;
        height: 20px;
        line-height: 20px;
    }
}
.status {
    font-size: 10px;
    height: 14px;
    color: #956BFF;
    max-width: 60%;
    display: -webkit-box;
    line-height: 14px;
    overflow: hidden;
    position: absolute;
    bottom: 5px;
    right: 0;
}
.gift_store {
    margin: 5px 30px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 5px;
    border-bottom: 2px solid #eee;
    color: #999;
    font-size: 14px;
    font-weight: 400;
    .platform {
        margin-left: 20px;
        font-weight: 400;
        color: rgb(228, 140, 39);
        font-size: 13px;
    }
    .keyw {
        margin-left: 30px;
        font-weight: 400;
        color: #333;
        font-size: 13px;
    }
    .filter {
        display: inline-block;
        margin-left: 20px;
        font-weight: 400;
        padding: 1px 4px;
        color: rgb(53, 150, 206);
        background-color: rgba(59, 160, 218, 0.274);
        font-size: 13px;
        text-align: center;
        border-radius: 3px;
    }
    .attention_container {
        margin-left: 60px;
    }
    .attention {
        display: inline-block;
        margin-top: 2px;
        margin-left: 20px;
        font-weight: 400;
        padding: 1px 4px;
        color: rgb(238, 9, 9);
        background-color: rgba(235, 86, 86, 0.137);
        font-size: 13px;
        text-align: center;
        border-radius: 3px;
    }
}
.gift_step {
    margin: 0 30px;
    padding: 5px;
    .step_top {
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
    .step_tip {
        display: block;
        width: 100%;
        margin: auto;
        font-size: 12px;
        p {
            color: rgb(42, 126, 165);
            display: inline-block;
        }
    }
}
.store_btn {
    float: right;
}
.good_input {
    margin-top: 5px;
}
.gray_img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    pointer-events: none;
}
.success {
    pointer-events: none;
}
.blank_row {
    width: 100%;
    margin: auto;
    height: 10px;
    border-bottom: 8px solid #eee;
}
.btns {
    position: fixed;
    width: 100%;
    max-width: 640px;
    bottom: 0px;
    margin: 0px 2.5%;
    padding: 20px 0;
    background-color: #fff;
}
.btn {
    width: 30%;
}
.abandon_select {
    text-align: center;
}
.weui-half-screen-dialog {
    z-index: 2000;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
.weui-half-screen-dialog_show {
    -webkit-transform: translateY(0);
    transform: translateY(0) !important;
}
.radio_container {
    width: 100%;
    padding: 10px 30px;
    font-family: Arial, Helvetica, sans-serif;
    .radio_title {
        text-align: center;
        font-size: 13px;
    }
    .option_container {
        width: 100%;
        margin-top: 20px;
    }
}
.option_button {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
}
.mistake_container {
    width: 100%;
}
.mistake_tip {
    text-align: center;
    font-size: 13px;
    color: red;
}
.input_container {
    width: 70%;
    margin-top: 20px;
    margin-left: 15%;
}
.submit_container {
    width: 100%;
}
.submit_info {
    margin: 5px 10px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 5px;
    border-bottom: 2px solid #eee;
    color: #333;
    font-size: 13px;
    font-weight: 500;
    span {
        float: right;
        font-size: 13px;
    }
}
.submit_info_p {
    color: red;
}
.submit_input_container {
    width: 100%;
    margin-top: 10px;
}
.submit_input_title {
    width: 90%;
    margin: 0 5%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.submit_input {
    width: 90%;
    margin: 5px 5%;
}
.show_img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    img {
        width: 100%;
        height: 100%;
    }
}
.attention_container {
    width: 90%;
    margin: 5px auto;
}
.attention_show {
    color: red;
    font-weight: bold;
    display: inline-block;
    border-bottom: 2px solid;
}
.my-dialog {
    width: 320px;
    margin: 0 auto;
    position: fixed;
    z-index: 5000;
    top: 50%;
    left: 16px;
    right: 16px;
    transform: translate(0, -50%);
    background-color: #fff;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-height: 90%;
}
.attention_title {
    margin-bottom: 5px;
}
.attention_list {
    width: 100%;
    .attention_item {
        width: 100%;
        display: flex;
        margin-top: 5px;
        .item_img {
            display: block;
            flex: 2;
        }
        .item_info {
            width: 80%;
            position: relative;
            display: block;
            flex: 8;
            text-align: left;
            .item_subtitle {
                font-size: 14px;
            }
            .item_tip {
                font-size: 13px;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 400;
                color: #999;
                margin-top: 3px;
            }
            .item_input {
                width: 100%;
                margin: 5px auto;
            }
            .item_xing {
                display: inline-block;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 400;
                color: #333;
                font-size: 14px;
            }
            .item_span {
                float: left;
            }
        }
    }
}
.activity_container {
    width: 100%;
    max-width: 640px;
    height: 100%;
    background: rgba(0, 0, 0, .66);
    z-index: 999;
    position: fixed;
    top: 0;
}
.activity_box {
    width: 80%;
    margin: 20% 10%;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
}
.activity_title {
    position: relative;
    width: 100%;
    height: 30px;
    line-height: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    color: #444;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}
.activity_img {
    margin-top: 10px;
    width: 100%;
    padding: 2px;
    height: 140px;
}
.activity_img img {
    width: 100%;
    height: 100%;
}
.activity_time {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
}
.time_left {
    width: 40%;
    text-align: center;
    float: left;
}
.time_right {
    float: left;
    width: 50%;
    margin: 0 5%;
}
.activity_tpwd {
    width: 100%;
    margin-top: 20px;
}
.activity_tpwd span {
    width: 30%;
    text-align: center;
    float: left;
    font-size: 13px;
    padding: 8px;
}
.activity_input {
    width: 60%;
    margin: 0 5%;
    float: left;
}
.activity_btn {
    width: 100%;
    margin: 10px -10px;
    text-align: right;
}
.pic_close {
    position: absolute;
    right: 13%;
    font-size: 0;
    line-height: initial;
}
.close_btn {
    position: absolute;
    right: -28px;
    top: -28px;
    width: 30px;
    height: 30px;
    margin-left: 0;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
}
.close_icon {
    background: url(../assets/icon.png) no-repeat;
    background-position: -149px -31px;
}
// kf_container
.kf_box {
    width: 60%;
    margin: 30% 20%;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.kf_title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: red;
}
.kf_content {
    width: 100%;
}
.kf {
    float: left;
    width: 80%;
    margin: 10% 10%;
    text-align: center;
    img {
        width: 100%;
        height: 100%;
    }
    span {
        font-size: 13px;
        color: rosybrown;
    }
}
.kf_button {
    width: 100%;
    text-align: center;
}
.yq_button {
    margin-top: 5px;
}
.home_header_right span {
    position: absolute;
    top: 0;
    width: 40px;
    margin-left: 5px;
}
.home_header_right {
    right: 50px;
}
.lijin {
    color: red;
}
</style>
